<template>
  <PersonalSite/>
</template>

<script>
import PersonalSite from './components/PersonalSite.vue'

export default {
  name: 'App',
  components: {
    PersonalSite
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 2%;
  position: absolute;
  width: 99%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
</style>
